<template>
    <v-main>
        <v-container class="min-h-full d-flex align-center justify-center pa-4">
            <slot></slot>
        </v-container>
    </v-main>
</template>

<script>
export default {
    props: {
        small: Boolean
    },
    created() {
        this.$store.dispatch("APPBAR_TRANSPARENTABLE");
    }
};
</script>
